import create from 'zustand';

const DARK_MODE = 'dark-mode';
const getDarkMode = () => JSON.parse(localStorage.getItem(DARK_MODE)) || false;

const useStore = create(set => ({
	darkMode: getDarkMode(),
	setDarkMode: darkMode => {
		localStorage.setItem(DARK_MODE, JSON.stringify(darkMode));
		set({ darkMode });
	}
}));

export default useStore;
