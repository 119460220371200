import { MailOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/UserContext';
import { openNotification } from '../../utils/openNotification';
import AsyncButton from '../AsyncButton';

const ResetPassword = () => {
	const [loading, setLoading] = useState(false);
	const { resetPassword } = useAuth();
	const navigate = useNavigate();
	const { Item } = Form;

	const onFinish = ({ email }) => {
		setLoading(true);
		resetPassword(email)
			.then(() => {
				openNotification('Пароль скинуто!', 'Перевірте свою поштову скриньку для подальших інструкцій', 'success');
				navigate('/login', { replace: true });
			})
			.catch(() => {
				openNotification('Помилка', 'Не вдалося скинути пароль! Перевірте чи правильно вказаний E-mail', 'error');
				setLoading(false);
			});
	};

	return (
		<>
			<Form name="reset-password" onFinish={onFinish}>
				<Item
					name="email"
					rules={[
						{
							required: true,
							message: 'Будь ласка, введіть коректний E-mail',
							type: 'email'
						}
					]}
				>
					<Input prefix={<MailOutlined />} type="email" placeholder="Ваш E-mail" size="large" />
				</Item>
				<Item>
					<AsyncButton type="primary" htmlType="submit" block disabled={loading} size="large">
						Скинути пароль
					</AsyncButton>
				</Item>
			</Form>
			<Link to="/login">Повернутися на сторінку входу</Link>
		</>
	);
};

export default ResetPassword;
