import { notification } from 'antd';

/*
title: 'Title of notfication'
message: 'Text of notification'
type (optional, default - info): 'success', 'info', 'warning', 'error'
placement (optional, default - top): 'top, 'bottom, 'topLeft', 'topRight', 'bottomLeft', 'bottomRight'
*/
export const openNotification = (title, message, type = 'info', placement = 'topRight') => {
	switch (title) {
		case 'auth/user-not-found':
			title = 'Помилка!';
			message = 'Такого користувача не знайдено!';
			break;
		case 'auth/wrong-password':
			title = 'Помилка!';
			message = 'Невірний пароль!';
			break;
		case 'auth/email-already-in-use':
			title = 'Помилка!';
			message = 'Користувач з такою адресою вже зареєстрований!';
			break;

		default:
			break;
	}

	notification[type]({
		message: title,
		description: message,
		placement
	});
};
