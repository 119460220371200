import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, MailOutlined } from '@ant-design/icons';
import { Form, Input, Space } from 'antd';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/UserContext';
import { openNotification } from '../../utils/openNotification';
import AsyncButton from '../AsyncButton';

const Login = () => {
	const { logIn } = useAuth();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const onFinish = ({ email, password }) => {
		setLoading(true);
		logIn(email, password)
			.then(({ user }) => {
				navigate('/', { replace: true });
				openNotification('Ласкаво просимо!', `Ви успішно увійшли в систему як ${user.email}!`, 'success');
			})
			.catch(({ code, message }) => {
				openNotification(code, message, 'error');
				setLoading(false);
			});
	};
	const { Item } = Form;

	const initialValues = {
		email: process.env.NODE_ENV === 'development' ? 'clackd1amond@gmail.com' : '',
		password: process.env.NODE_ENV === 'development' ? '123456' : ''
	};

	return (
		<>
			<Form name="login" onFinish={onFinish} initialValues={initialValues}>
				<Item
					name="email"
					rules={[
						{
							required: true,
							message: 'Будь ласка, введіть коректний E-mail',
							type: 'email'
						}
					]}
				>
					<Input prefix={<MailOutlined />} type="email" placeholder="E-mail" size="large" />
				</Item>
				<Item
					name="password"
					rules={[
						{
							required: true,
							message: 'Будь ласка, введіть пароль'
						}
					]}
				>
					<Input.Password
						prefix={<LockOutlined />}
						placeholder="Password"
						size="large"
						iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
					/>
				</Item>
				<Item>
					<AsyncButton type="primary" block htmlType="submit" disabled={loading} size="large">
						Увійти
					</AsyncButton>
				</Item>
			</Form>

			<Space direction="vertical">
				<Link to="/login/forgot-password">Забули пароль?</Link>
				<Link to="/login/register">Немає акаунту, зареєструватись</Link>
			</Space>
		</>
	);
};

export default Login;
