import { ConfigProvider } from 'antd';
import ukUA from 'antd/lib/locale/uk_UA';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { MenuProvider } from '../../context/MenuContext';
import { UserProvider } from '../../context/UserContext';
import useStore from '../../store';
import { AppRoutes as Routes } from '../Routes/Routes';
import './App.less';

const themes = {
	dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
	light: `${process.env.PUBLIC_URL}/light-theme.css`
};

function App() {
	const { darkMode } = useStore();

	return (
		<ThemeSwitcherProvider themeMap={themes} defaultTheme={darkMode ? 'dark' : 'light'}>
			<ConfigProvider locale={ukUA}>
				<UserProvider>
					<MenuProvider>
						<div className="App">
							<Routes />
						</div>
					</MenuProvider>
				</UserProvider>
			</ConfigProvider>
		</ThemeSwitcherProvider>
	);
}

export default App;
