import { LeftOutlined } from '@ant-design/icons';
import { Button, Divider, Select } from 'antd';
import axios from '../../utils/axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMenuContext } from '../../context/MenuContext';
import { useAuth } from '../../context/UserContext';
import { openNotification } from '../../utils/openNotification';
import { renderRole } from '../../utils/renderRoles';

const UserPage = () => {
	const [loading, setLoading] = useState(false);
	const [curUser, setCurUser] = useState(null);
	const { id } = useParams();
	const navigate = useNavigate();
	const { user, userData } = useAuth();

	const { setPageTitle, setSelectedKeys } = useMenuContext();

	useEffect(() => {
		const updateMenuAndTitle = () => {
			setPageTitle('Сторінка користувача');
			setSelectedKeys('2');
		};
		updateMenuAndTitle();
		return updateMenuAndTitle;
	}, [setPageTitle, setSelectedKeys, id]);

	useEffect(() => {
		const getUser = () => {
			if (!id) return;
			axios.get(`getItem.php?table=users&id=${id}`).then(res => {
				const { data } = res;
				setCurUser(data);
			});
		};
		getUser();
		return getUser;
	}, [id]);

	const goBack = () => {
		navigate(-1);
	};

	const handleChange = value => {
		setLoading(true);
		axios
			.post('updateRole.php', `id=${id}&role=${value}`)
			.then(res => {
				setLoading(false);
				openNotification('Успіх', 'Роль користувача була змінена', 'success');
				setCurUser({ ...curUser, role: value });
			})
			.catch(err => {
				setLoading(false);
				openNotification('Помилка', 'Не вдалося змінити роль користувача', 'error');
			});
	};

	const { Option } = Select;

	const role = () => {
		if (userData.role !== 'admin' || user.uid === id) {
			return renderRole(curUser.role);
		}
		return (
			<Select defaultValue={curUser.role} disabled={loading} loading={loading} onChange={handleChange}>
				<Option value="admin">Адміністратор</Option>
				<Option value="user">Користувач</Option>
				<Option value="manager">Менеджер</Option>
			</Select>
		);
	};

	return (
		curUser && (
			<>
				<h2>{curUser.email}</h2>
				<Divider />
				<p>id: {id}</p>
				<p>Роль: {role()}</p>
				<Divider />
				<Button icon={<LeftOutlined />} size="large" onClick={goBack}>
					Назад
				</Button>
			</>
		)
	);
};
export default UserPage;
