import { Card, Divider, Layout, Space } from 'antd';
import { Outlet } from 'react-router-dom';
import ProfileButton from '../ProfileButton';
import Sidebar from '../Sidebar/Sidebar';
import ThemeSwitcher from '../ThemeSwitcher';
import styles from './Layout.module.less';

export const MainLayout = ({ title }) => {
	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Sidebar />
			<Layout>
				<Layout.Header className={styles.header}>
					<Space className={styles.headerSpace} wrap align="center" split={<Divider type="vertical" />}>
						<ProfileButton />
						<ThemeSwitcher />
					</Space>
				</Layout.Header>
				<Layout>
					<Layout.Content className={styles.pageLayoutContainer}>
						<Card className={styles.pageLayoutCard} title={title}>
							<Outlet />
						</Card>
					</Layout.Content>
				</Layout>
			</Layout>
		</Layout>
	);
};
