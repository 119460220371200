import { Route, Routes } from 'react-router-dom';
import { useMenuContext } from '../../context/MenuContext';
import MailLists from '../../pages/MailLists/MailLists';
import NotFound from '../../pages/NotFound';
import Profile from '../../pages/Profile';
import Reports from '../../pages/Reports';
import SendList from '../../pages/SendList';
import Settings from '../../pages/Settings';
import UserPage from '../../pages/UserPage';
import Users from '../../pages/Users';
import { LoginLayout } from '../Layout/LoginLayout';
import Login from '../Login';
import MailListEdit from '../MailListEdit';
import MailListNew from '../MailListNew';
import ProtectedRoute from '../ProtectedRoute';
import Register from '../Register';
import ResetPassword from '../ResetPassword';

export const AppRoutes = () => {
	const { pageTitle } = useMenuContext();

	return (
		<Routes>
			<Route path="login" element={<LoginLayout />}>
				<Route index element={<Login />} />
				<Route path="register" element={<Register />} />
				<Route path="forgot-password" element={<ResetPassword />} />
			</Route>

			<Route element={<ProtectedRoute title={pageTitle} />}>
				<Route path="/" element={<Reports />} />

				<Route path="list">
					<Route index element={<SendList />} />
					<Route path=":id" element={<Settings />} />
					<Route path="new" element={<Settings />} />
				</Route>

				<Route path="mlists">
					<Route index element={<MailLists />} />
					<Route path=":id" element={<MailListEdit />} />
					<Route path="new" element={<MailListNew />} />
				</Route>

				<Route path="profile" element={<Profile />} />

				<Route path="users">
					<Route index element={<Users />} />
					<Route path=":id" element={<UserPage />} />
				</Route>
			</Route>

			<Route path="*" element={<NotFound />} />
		</Routes>
	);
};
