import { Helmet } from 'react-helmet';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/UserContext';
import { MainLayout } from '../Layout/MainLayout';

const ProtectedRoute = ({ title = '', redirectPath = '/login', children }) => {
	const { user, setCurrentLocation } = useAuth();
	const location = useLocation();

	if (!user) {
		setCurrentLocation(location.pathname);
		return <Navigate to={redirectPath} replace />;
	}

	return children ? (
		children
	) : (
		<>
			<Helmet>
				<title>{title} | Price Sender</title>
			</Helmet>
			<MainLayout title={title} />
		</>
	);
};

export default ProtectedRoute;
