import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const AsyncButton = ({ disabled, loadingText = 'Зачекайте...', children, ...props }) => {
	return (
		<Button disabled={disabled} icon={disabled ? <LoadingOutlined /> : null} {...props}>
			{disabled ? loadingText : children}
		</Button>
	);
};
export default AsyncButton;
