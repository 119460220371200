import {
	CheckOutlined,
	CloseOutlined,
	DeleteOutlined,
	EditOutlined,
	EyeOutlined,
	PlusOutlined
} from '@ant-design/icons';
import { Button, Empty, Space, Switch, Table } from 'antd';
import axios from '../../utils/axios';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMenuContext } from '../../context/MenuContext';
import { useAuth } from '../../context/UserContext';
import { openNotification } from '../../utils/openNotification';

const SendList = () => {
	const [tableData, setTableData] = useState([]);
	const { userData } = useAuth();
	const { setPageTitle, setSelectedKeys } = useMenuContext();
	const navigate = useNavigate();

	useEffect(() => {
		const updateMenuAndTitle = () => {
			setPageTitle('Список розсилок');
			setSelectedKeys('2');
		};
		updateMenuAndTitle();
		return updateMenuAndTitle;
	}, [setPageTitle, setSelectedKeys]);

	useEffect(() => {
		const fetchData = () => {
			axios.get('getList.php?table=settings').then(res => {
				const { data } = res;
				setTableData(
					data.map(item => ({
						key: item.id,
						id: item.id,
						title: item.title,
						mailfrom: item.mailfrom,
						namefrom: item.namefrom,
						active: item.active === 'true'
					}))
				);
			});
		};
		fetchData();

		return fetchData;
	}, []);

	const removeSend = id => {
		axios
			.post('removeItem.php', `table=settings&id=${id}`)
			.then(res => {
				const { data } = res;
				openNotification(data.header, data.message, data.type);
				setTableData(tableData.filter(item => item.id !== id));
			})
			.catch(err => {
				openNotification('Помилка', err.message, 'error');
			});
	};

	const toggleActive = (id, title, active) => {
		axios
			.post('updateActive.php', `table=settings&id=${id}&active=${active}&title=${title}`)
			.then(res => {
				const { data } = res;
				openNotification(data.header, data.message, data.type);
				setTableData(tableData.map(item => (item.id === id ? { ...item, active: !item.active } : item)));
			})
			.catch(err => {
				openNotification('Помилка', err.message, 'error');
			});
	};

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id'
		},
		{
			title: 'Назва',
			dataIndex: 'title',
			key: 'title'
		},
		{
			title: 'Відправник',
			dataIndex: 'mailfrom',
			key: 'mailfrom',
			render: (text, record) => (
				<span>
					<strong>{record.namefrom}</strong> ({record.mailfrom})
				</span>
			)
		},
		{
			title: 'Операції',
			dataIndex: 'action',
			key: 'action',
			render: (text, record) =>
				userData.role === 'user' ? (
					<Button
						type="link"
						onClick={() => {
							navigate(`/list/${record.id}`);
						}}
						icon={<EyeOutlined />}
					>
						Переглянути налаштування
					</Button>
				) : (
					<Space size="middle">
						<Switch
							checkedChildren={<CheckOutlined />}
							unCheckedChildren={<CloseOutlined />}
							checked={record.active}
							onChange={active => {
								toggleActive(record.id, record.title, active);
							}}
						/>
						<Button
							type="link"
							onClick={() => {
								navigate(`/list/${record.id}`);
							}}
							icon={<EditOutlined />}
						>
							Редагувати
						</Button>
						<Button
							onClick={() => {
								removeSend(record.id);
							}}
							type="link"
							icon={<DeleteOutlined />}
						>
							Видалити
						</Button>
					</Space>
				)
		}
	];

	return (
		userData && (
			<Space direction="vertical" size="large" style={{ width: '100%' }}>
				<Link to="/list/new">
					{userData.role !== 'user' && (
						<Button type="primary" icon={<PlusOutlined />}>
							Додати розсилку
						</Button>
					)}
				</Link>
				{tableData.length > 0 ? (
					<Table dataSource={tableData} columns={columns} pagination={{ position: ['topRight'] }} />
				) : (
					<Empty description="Немає розсилок" />
				)}
			</Space>
		)
	);
};
export default SendList;
