import { Space } from 'antd';
import { Link } from 'react-router-dom';
import logo from './logo.svg';

const Logo = ({ short = false }) => {
	return (
		<Space direction="vertical" align="center" style={{ padding: short ? '0.5em' : '1em' }}>
			<Link to="/">
				<img src={logo} alt="Логотип" width="100%" />
			</Link>
			{!short && <h3>Price Sender</h3>}
		</Space>
	);
};
export default Logo;
