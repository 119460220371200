import { Tag } from 'antd';

const setRoleData = role => {
	switch (role) {
		case 'admin':
			return { color: 'volcano', text: 'Адміністратор' };
		case 'user':
			return { color: 'cyan', text: 'Користувач' };
		case 'manager':
			return { color: 'purple', text: 'Менеджер' };
		default:
			return 'geekblue';
	}
};

export const renderRole = role => {
	const color = setRoleData(role).color;
	const text = setRoleData(role).text;

	return <Tag color={color}>{text}</Tag>;
};
