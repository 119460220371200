import { Switch } from 'antd';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import useStore from '../../store';

const ThemeSwitcher = () => {
	const { darkMode, setDarkMode } = useStore();
	const { switcher, themes } = useThemeSwitcher();

	const switchTheme = isDarkMode => {
		setDarkMode(isDarkMode);
		switcher({ theme: isDarkMode ? themes.dark : themes.light });
	};

	return <Switch checked={darkMode} onChange={switchTheme} checkedChildren="🌙" unCheckedChildren="☀️" />;
};
export default ThemeSwitcher;
