import { Col, Form, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import axios from '../../utils/axios';

const PriceSelect = ({ checkUser, priceName, setPriceName }) => {
	const [loading, setLoading] = useState(false);
	const [files, setFiles] = useState([]);
	const { Item } = Form;
	const { Option } = Select;

	useEffect(() => {
		const fetchFiles = () => {
			setLoading(true);
			axios.get('getFiles.php').then(res => {
				const { data } = res;
				setFiles(Object.values(data));
				setLoading(false);
			});
		};
		fetchFiles();
		return fetchFiles;
	}, []);

	const onChange = value => {
		setPriceName(value);
	};

	const filterOption = (input, option) => option.children.toLowerCase().includes(input.toLowerCase());
	const filterSort = (optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase());

	return (
		<Row gutter={16}>
			<Col xs={{ span: 24 }} lg={{ span: 8 }}>
				<Item
					name="sender-file"
					label="Файл прайсу"
					rules={[{ required: true, message: 'Будь ласка, оберіть файл прайсу зі списку' }]}
				>
					<Select
						showSearch
						loading={loading}
						disabled={loading || checkUser}
						optionFilterProp="children"
						filterOption={filterOption}
						filterSort={filterSort}
						onChange={onChange}
						size="large"
					>
						{files.map(file => (
							<Option key={file} value={file}>
								{file}
							</Option>
						))}
					</Select>
				</Item>
			</Col>
			<Col xs={{ span: 24 }} lg={{ span: 8 }}></Col>
		</Row>
	);
};
export default PriceSelect;
