import {
	CheckCircleOutlined,
	CheckOutlined,
	CloseOutlined,
	EyeInvisibleOutlined,
	EyeTwoTone,
	LeftOutlined
} from '@ant-design/icons';
import { Editor } from '@tinymce/tinymce-react';
import { Button, Col, Divider, Empty, Form, Input, Row, Select, Space, Spin, Switch } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AsyncButton from '../../components/AsyncButton';
import { useMenuContext } from '../../context/MenuContext';
import { useAuth } from '../../context/UserContext';
import axios from '../../utils/axios';
import { openNotification } from '../../utils/openNotification';
import PriceSelect from './PriceSelect';
import TestSend from './TestSend';

const Settings = () => {
	const [loading, setLoading] = useState(false);
	const [initialValues, setInitialValues] = useState(null);
	const [priceName, setPriceName] = useState(null);
	const [mailLists, setMailLists] = useState(null);

	const { userData } = useAuth();
	const checkUser = userData && userData.role === 'user';

	const { id } = useParams();

	const { setPageTitle, setSelectedKeys } = useMenuContext();
	const { Item } = Form;
	const { Option } = Select;

	const navigate = useNavigate();

	useEffect(() => {
		const updateMenuAndTitle = () => {
			setPageTitle(id ? 'Налаштування розсилки' : 'Нова розсилка');
			setSelectedKeys('2');
		};
		updateMenuAndTitle();
		return updateMenuAndTitle;
	}, [setPageTitle, setSelectedKeys, id]);

	useEffect(() => {
		const fetchData = () => {
			if (!id) return;
			axios.get(`getItem.php?table=settings&id=${id}`).then(res => {
				const { data } = res;

				setInitialValues({
					'sender-active': data.active === 'true',
					'sender-title': data.title,
					'sender-subject': data.subject,
					'sender-from': data.mailfrom,
					'sender-name': data.namefrom,
					'sender-smtp': data.smtp,
					'sender-port': data.port,
					'sender-mlist': data.mlist,
					'sender-text': data.mailtext,
					'sender-file-name': data.file,
					'sender-password': data.password,
					'sender-file': data.file,
					'sender-file-send-name': data.filesendname
				});
				setPriceName(data.file);
			});
		};
		fetchData();

		return fetchData;
	}, [id]);

	useEffect(() => {
		const getLists = () => {
			axios.get('getList.php?table=mlists').then(res => {
				const { data } = res;
				setMailLists(data);
			});
		};
		getLists();

		return getLists;
	}, []);

	const onFinish = values => {
		setLoading(true);

		const editorContent =
			typeof values['sender-text'] === 'string' ? values['sender-text'] : values['sender-text'].level.content;

		const mlist = values['sender-mlist'];
		const active = values['sender-active'];
		const title = values['sender-title'];
		const mailfrom = values['sender-from'];
		const smtp = values['sender-smtp'];
		const port = values['sender-port'];
		const subject = values['sender-subject'];
		const mailtext = editorContent;
		const password = values['sender-password'];
		const namefrom = values['sender-name'];
		const filename = values['sender-file'];
		const filesendname = values['sender-file-send-name'];

		const data = {
			id: id ? id : 0,
			active,
			title,
			mailfrom,
			smtp,
			port,
			subject,
			mailtext,
			mlist,
			password,
			namefrom,
			filename,
			filesendname
		};

		const postData = new URLSearchParams(data).toString();

		axios
			.post('updateSettings.php', postData)
			.then(res => {
				const { data } = res;
				setLoading(false);
				openNotification(data.title, data.message, data.type);
				if (!id) navigate('/list');
			})
			.catch(err => {
				setLoading(false);
				openNotification('Помилка', err.message, 'error');
			});
	};

	const goBack = () => {
		navigate(-1);
	};

	return id && !initialValues ? (
		<Spin size="large" />
	) : (
		userData && (
			<>
				{id && (
					<>
						<TestSend id={id} priceName={priceName} />
						<Divider />
					</>
				)}
				<Form name="sender-settings" layout="vertical" onFinish={onFinish} initialValues={initialValues}>
					<Item
						name="sender-title"
						rules={[
							{ required: true, message: 'Будь ласка, вкажіть назву' },
							{ max: 50, message: 'Максимальна кількість символів - 50. Будь ласка, скоротіть' }
						]}
					>
						<Input placeholder="Назва розсилки" size="large" disabled={checkUser} />
					</Item>
					<Item labelCol={{ offset: 0 }} label="Розсилка активна" name="sender-active" valuePropName="checked">
						<Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} disabled={checkUser} />
					</Item>
					<PriceSelect
						initialValue={initialValues && initialValues['sender-file']}
						checkUser={checkUser}
						priceName={priceName}
						setPriceName={setPriceName}
					/>

					<Divider />
					<Row gutter={16}>
						<Col xs={{ span: 24 }} lg={{ span: 12 }}>
							<Item
								label="Тема листа"
								name="sender-subject"
								rules={[{ required: true, message: 'Будь ласка, вкажіть тему' }]}
							>
								<Input placeholder="Прайс Kaiser Україна" size="large" disabled={checkUser} />
							</Item>
							<Item label="Текст листа" name="sender-text" valuePropName="initialValue">
								<Editor
									apiKey="w4h3xl7o1swpjqq8gzy4r2hg8e3audi2yroufi3phdz4a4sp"
									init={{
										menubar: true,
										height: 500,
										resize: true,
										language: 'uk',
										plugins:
											'advlist autolink autoresize lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table help wordcount',
										toolbar:
											'fontfamily fontsize | bold italic underline strikethrough backcolor | blocks | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
									}}
								/>
							</Item>
						</Col>
						<Col xs={{ span: 24 }} lg={{ span: 12 }}>
							<Item
								label="E-mail відправника"
								name="sender-from"
								rules={[
									{
										required: true,
										message: 'Будь ласка, введіть коректний E-mail',
										type: 'email'
									}
								]}
							>
								<Input type="email" placeholder="sendingthepricekaiser@gmail.com" size="large" disabled={checkUser} />
							</Item>
							<Item
								label="Ім’я відправника"
								name="sender-name"
								rules={[
									{
										required: true,
										message: 'Будь ласка, вкажіть ім’я відправника'
									}
								]}
							>
								<Input placeholder="Kaiser Price" size="large" disabled={checkUser} />
							</Item>
							<Item
								label="SMTP-сервер"
								name="sender-smtp"
								rules={[{ required: true, message: 'Будь ласка, вкажіть адресу SMTP-сервера' }]}
							>
								<Input placeholder="smtp.gmail.com" size="large" disabled={checkUser} />
							</Item>
							<Item label="Порт" name="sender-port" rules={[{ required: true, message: 'Будь ласка, вкажіть порт' }]}>
								<Input placeholder="587" size="large" disabled={checkUser} />
							</Item>
							<Item
								label={
									<>
										Пароль застосунку
										{!checkUser && (
											<sup style={{ marginLeft: '0.5em' }}>
												<a
													target="_blank"
													rel="noreferrer"
													href="https://support.google.com/accounts/answer/185833?authuser=2&hl=uk"
												>
													Що це?
												</a>
											</sup>
										)}
									</>
								}
								name="sender-password"
								rules={[
									{
										required: true,
										message: 'Будь ласка, вкажіть пароль'
									},
									{ min: 16, message: 'Пароль має містити 16 символів' },
									{ max: 16, message: 'Пароль має містити 16 символів' }
								]}
							>
								<Input.Password
									disabled={checkUser}
									placeholder="16-значний код"
									size="large"
									iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
								/>
							</Item>
							<Item
								label="Назва файлу прайсу при відправці"
								name="sender-file-send-name"
								extra={`До назви буде автоматично додану поточну дату. Наприклад: "Прайс Kaiser_${moment().format(
									'MM-DD-YYYY'
								)}.xlsx"`}
							>
								<Input placeholder="Прайс Kaiser" size="large" disabled={checkUser} />
							</Item>
							<Item
								label="Список отримувачів"
								name="sender-mlist"
								rules={[{ required: true, message: 'Будь ласка, вкажіть список отримувачів' }]}
							>
								<Select
									loading={!mailLists}
									disabled={!mailLists}
									notFoundContent={
										<Empty
											description={
												<span>
													Не створено жодного списку отримувачів. <Link to="/mlists/new">Створити новий</Link>
												</span>
											}
										/>
									}
								>
									{mailLists &&
										mailLists.map(item => (
											<Option key={item.id} value={item.id}>
												{item.title}
											</Option>
										))}
								</Select>
							</Item>
						</Col>
						<Divider />

						<Space>
							<Button icon={<LeftOutlined />} size="large" onClick={goBack}>
								Назад
							</Button>
							<AsyncButton
								type="primary"
								htmlType="submit"
								disabled={loading}
								size="large"
								icon={<CheckCircleOutlined />}
							>
								Зберегти
							</AsyncButton>
						</Space>
					</Row>
				</Form>
			</>
		)
	);
};
export default Settings;
