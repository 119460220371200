import { CheckCircleOutlined, LeftOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMenuContext } from '../../context/MenuContext';
import { useAuth } from '../../context/UserContext';
import axios from '../../utils/axios';
import { openNotification } from '../../utils/openNotification';
import AsyncButton from '../AsyncButton';

const MailListNew = () => {
	const [loading, setLoading] = useState(false);

	const { userData } = useAuth();
	const checkUser = userData && userData.role === 'user';

	const { setPageTitle, setSelectedKeys } = useMenuContext();
	const { Item, List } = Form;

	const navigate = useNavigate();

	useEffect(() => {
		const updateMenuAndTitle = () => {
			setPageTitle('Новий список отримувачів');
			setSelectedKeys('4');
		};
		updateMenuAndTitle();
		return updateMenuAndTitle;
	}, [setPageTitle, setSelectedKeys]);

	const onFinish = values => {
		const { title, contacts } = values;

		if (!contacts) {
			openNotification('Помилка', 'Не вказано жодного отримувача', 'error');
			return;
		}

		setLoading(true);

		const data = { title, contacts: JSON.stringify(contacts) };
		const postData = new URLSearchParams(data).toString();
		axios
			.post('createMailList.php', postData)
			.then(res => {
				navigate('/mlists', { replace: true });
				openNotification('Успіх', 'Новий список створено', 'success');
			})
			.catch(err => {
				setLoading(false);
				openNotification('Помилка', err.message, 'error');
			});
	};

	const goBack = () => {
		navigate(-1);
	};

	return (
		userData && (
			<Form name="sender-mlist" layout="vertical" autoComplete="off" onFinish={onFinish}>
				<Item
					name="title"
					rules={[
						{ required: true, message: 'Будь ласка, вкажіть назву' },
						{ max: 50, message: 'Максимальна кількість символів - 50. Будь ласка, скоротіть' }
					]}
				>
					<Input placeholder="Назва списку" size="large" disabled={checkUser} />
				</Item>
				<Divider />
				<h3>Контакти:</h3>
				<List name="contacts">
					{(fields, { add, remove }) => (
						<>
							{fields.map(({ key, name, ...restField }) => (
								<Space
									key={key}
									style={{
										display: 'flex',
										marginBottom: 8
									}}
									align="baseline"
								>
									<Form.Item
										{...restField}
										name={[name, 'email']}
										rules={[
											{
												required: true,
												message: 'Введіть коректний E-mail',
												type: 'email'
											}
										]}
									>
										<Input type="email" placeholder="E-mail" />
									</Form.Item>
									<Form.Item
										{...restField}
										name={[name, 'name']}
										rules={[
											{
												required: true,
												message: 'Введіть ім’я або назву'
											}
										]}
									>
										<Input placeholder="Ім’я або назва" />
									</Form.Item>
									<MinusCircleOutlined onClick={() => remove(name)} />
								</Space>
							))}
							<Form.Item>
								<Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
									Додати контакт
								</Button>
							</Form.Item>
						</>
					)}
				</List>
				<Divider />
				<Space align="center">
					<Button icon={<LeftOutlined />} size="large" onClick={goBack}>
						Назад
					</Button>
					<AsyncButton type="primary" htmlType="submit" size="large" disabled={loading} icon={<CheckCircleOutlined />}>
						Зберегти
					</AsyncButton>
				</Space>
			</Form>
		)
	);
};
export default MailListNew;
