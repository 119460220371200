import axios from '../utils/axios';
import {
	createUserWithEmailAndPassword,
	GoogleAuthProvider,
	onAuthStateChanged,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signInWithPopup,
	signOut,
	updatePassword
} from 'firebase/auth';
import { auth } from '../firebase';

const { createContext, useState, useEffect, useContext } = require('react');

const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [userData, setUserData] = useState(null);
	const [currentLocation, setCurrentLocation] = useState('/');
	const [loading, setLoading] = useState(true);

	const logIn = (email, password) => {
		return signInWithEmailAndPassword(auth, email, password);
	};

	const signInWithGoogle = () => {
		const provider = new GoogleAuthProvider();
		return signInWithPopup(auth, provider);
	};

	const logOut = () => {
		return signOut(auth);
	};

	const register = (email, password) => {
		return createUserWithEmailAndPassword(auth, email, password);
	};

	const resetPassword = email => {
		return sendPasswordResetEmail(auth, email);
	};

	const updateUserPassword = password => {
		const currentUser = auth.currentUser;
		return updatePassword(currentUser, password);
	};

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, currentUser => {
			setUser(currentUser);
			setLoading(false);
		});

		return unsubscribe;
	}, [user]);

	useEffect(() => {
		const getUserData = currentUser => {
			if (currentUser) {
				axios.get(`getItem.php?table=users&id=${currentUser.uid}`).then(res => {
					const { data } = res;
					setUserData(data);
				});
			}
		};
		getUserData(user);

		return getUserData;
	}, [user]);

	const value = {
		user,
		userData,
		logIn,
		logOut,
		currentLocation,
		setCurrentLocation,
		resetPassword,
		signInWithGoogle,
		updateUserPassword,
		register
	};

	return <UserContext.Provider value={value}>{!loading && children}</UserContext.Provider>;
};

export const useAuth = () => {
	return useContext(UserContext);
};
