import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Empty, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMenuContext } from '../../context/MenuContext';
import { useAuth } from '../../context/UserContext';
import axios from '../../utils/axios';
import { openNotification } from '../../utils/openNotification';

const MailLists = () => {
	const [tableData, setTableData] = useState([]);
	const { userData } = useAuth();
	const { setPageTitle, setSelectedKeys } = useMenuContext();
	const navigate = useNavigate();

	useEffect(() => {
		const updateMenuAndTitle = () => {
			setPageTitle('Списки отримувачів');
			setSelectedKeys('4');
		};
		updateMenuAndTitle();
		return updateMenuAndTitle;
	}, [setPageTitle, setSelectedKeys]);

	useEffect(() => {
		const fetchData = () => {
			axios.get('getList.php?table=mlists').then(res => {
				const { data } = res;
				setTableData(
					data.map(item => ({
						key: item.id,
						id: item.id,
						title: item.title,
						count: item.count
					}))
				);
			});
		};
		fetchData();

		return fetchData;
	}, []);

	const removeList = id => {
		axios
			.post('removeItem.php', `table=mlists&id=${id}`)
			.then(res => {
				const { data } = res;
				openNotification(data.header, data.message, data.type);
				setTableData(tableData.filter(item => item.id !== id));
			})
			.catch(err => {
				openNotification('Помилка', err.message, 'error');
			});
	};

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id'
		},
		{
			title: 'Назва',
			dataIndex: 'title',
			key: 'title'
		},
		{
			title: 'Кількість контактів',
			dataIndex: 'count',
			key: 'count'
		},
		{
			title: 'Операції',
			dataIndex: 'action',
			key: 'action',
			render: (text, record) =>
				userData.role === 'user' ? (
					<Button
						type="link"
						onClick={() => {
							navigate(`/mlists/${record.id}`);
						}}
						icon={<EyeOutlined />}
					>
						Переглянути налаштування
					</Button>
				) : (
					<Space size="middle">
						<Button
							type="link"
							onClick={() => {
								navigate(`/mlists/${record.id}`);
							}}
							icon={<EditOutlined />}
						>
							Редагувати
						</Button>
						<Button
							onClick={() => {
								removeList(record.id);
							}}
							type="link"
							icon={<DeleteOutlined />}
						>
							Видалити
						</Button>
					</Space>
				)
		}
	];

	return (
		userData && (
			<Space direction="vertical" size="large" style={{ width: '100%' }}>
				<Link to="/mlists/new">
					{userData.role !== 'user' && (
						<Button type="primary" icon={<PlusOutlined />}>
							Новий список
						</Button>
					)}
				</Link>
				{tableData.length > 0 ? (
					<Table dataSource={tableData} columns={columns} pagination={{ position: ['topRight'] }} />
				) : (
					<Empty description="Немає списків отримувачів" />
				)}
			</Space>
		)
	);
};
export default MailLists;
