import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';

const NotFound = () => {
	return (
		<Result
			status="404"
			title="Помилка 404"
			subTitle="Вибачте, такої сторінки не існує."
			extra={
				<Link to="/">
					<Button type="primary">До звітів</Button>
				</Link>
			}
		/>
	);
};
export default NotFound;
