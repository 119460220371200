import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	DeleteOutlined,
	EditOutlined,
	LeftOutlined,
	PlusOutlined,
	SearchOutlined
} from '@ant-design/icons';
import { Button, Divider, Form, Input, InputNumber, Space, Table, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useNavigate, useParams } from 'react-router-dom';
import { useMenuContext } from '../../context/MenuContext';
import { useAuth } from '../../context/UserContext';
import axios from '../../utils/axios';
import { openNotification } from '../../utils/openNotification';
import AsyncButton from '../AsyncButton';

const MailListEdit = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [tableData, setTableData] = useState(null);
	const [editingKey, setEditingKey] = useState('');
	const [editingState, setEditingState] = useState(false);
	const [title, setTitle] = useState('');
	const [count, setCount] = useState(0);
	const ref = useRef(null);

	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);

	const isEditing = record => record.key === editingKey;

	const { id } = useParams();
	const { userData } = useAuth();
	const { Title } = Typography;

	const checkUser = userData && userData.role === 'user';

	const { setPageTitle, setSelectedKeys } = useMenuContext();

	const navigate = useNavigate();

	useEffect(() => {
		const updateMenuAndTitle = () => {
			setPageTitle('Редагування списку отримувачів');
			setSelectedKeys('4');
		};
		updateMenuAndTitle();
		return updateMenuAndTitle;
	}, [setPageTitle, setSelectedKeys, id]);

	useEffect(() => {
		const getList = () => {
			setLoading(true);
			if (!id) return;
			axios.get(`getItem.php?table=mlists&id=${id}`).then(res => {
				const { data } = res;
				const contacts = JSON.parse(data.contacts);
				setCount(contacts.length);
				setTableData(
					contacts.map((contact, i) => ({
						key: i,
						email: contact.email,
						name: contact.name
					}))
				);
				setTitle(data.title);
				setLoading(false);
			});
		};

		getList();
		return getList;
	}, [id]);

	const goBack = () => {
		navigate(-1);
	};

	const updateList = () => {
		if (editingState) {
			openNotification('Увага', 'Ви не закінчили редагування', 'warning');
			return;
		}
		setLoading(true);

		const contacts = tableData.map(contact => ({
			email: contact.email,
			name: contact.name
		}));

		const data = { id, title, contacts: JSON.stringify(contacts) };
		const postData = new URLSearchParams(data).toString();

		axios
			.post('updateMailList.php', postData)
			.then(res => {
				setLoading(false);
				openNotification('Успіх', 'Список отримувачів був змінений', 'success');
			})
			.catch(err => {
				setLoading(false);
				openNotification('Помилка', err.message, 'error');
			});
	};

	const edit = record => {
		form.setFieldsValue({
			email: '',
			name: '',
			...record
		});
		setEditingKey(record.key);
		setEditingState(true);
	};

	const cancel = () => {
		setEditingKey('');
		setEditingState(false);
	};

	const save = async key => {
		try {
			const row = await form.validateFields();
			const newData = [...tableData];
			const index = newData.findIndex(item => key === item.key);

			if (index > -1) {
				const item = newData[index];
				newData.splice(index, 1, { ...item, ...row });
				setTableData(newData);
				setEditingKey('');
				setEditingState(false);
			} else {
				newData.push(row);
				setTableData(newData);
				setEditingKey('');
				setEditingState(false);
			}
		} catch (errInfo) {
			// console.log('Validate Failed:', errInfo);
		}
	};

	const handleDelete = key => {
		if (tableData.length === 1) {
			openNotification('Помилка', 'Неможливо видалити останній елемент', 'error');
			return;
		}
		const newData = tableData.filter(item => item.key !== key);
		setTableData(newData);
		setCount(newData.length);
	};

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = clearFilters => {
		clearFilters();
		setSearchText('');
	};

	const getColumnSearchProps = (dataIndex, fieldName) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Пошук по полю '${fieldName}'`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Пошук
					</Button>
					<Button
						onClick={() => {
							clearFilters && handleReset(clearFilters);
							handleSearch(selectedKeys, confirm, dataIndex);
							setSearchText('');
						}}
						size="small"
						style={{ width: 90 }}
					>
						Скинути
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							close();
						}}
					>
						Закрити
					</Button>
				</Space>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownOpenChange: visible => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
		render: text =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			)
	});

	const columns = [
		{
			title: 'E-mail',
			dataIndex: 'email',
			key: 'email',
			width: '40%',
			editable: true,
			sorter: (a, b) => a.email.localeCompare(b.email),
			...getColumnSearchProps('email', 'E-mail')
		},
		{
			title: 'Ім’я або назва',
			dataIndex: 'name',
			key: 'name',
			width: '40%',
			editable: true,
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.name.localeCompare(b.name),
			...getColumnSearchProps('name', 'Ім’я або назва')
		},
		{
			title: 'Операції',
			dataIndex: 'operation',
			hidden: checkUser,
			render: (_, record) => {
				if (checkUser) return;
				const editable = isEditing(record);
				return editable ? (
					<>
						<Button
							type="link"
							onClick={() => save(record.key)}
							style={{
								marginRight: 8
							}}
							icon={<CheckCircleOutlined />}
						>
							Зберегти
						</Button>
						<Button type="link" onClick={cancel} icon={<CloseCircleOutlined />}>
							Відминити
						</Button>
					</>
				) : (
					<Space>
						<Button type="link" onClick={() => edit(record)} icon={<EditOutlined />}>
							Редагувати
						</Button>
						<Button type="link" onClick={() => handleDelete(record.key)} icon={<DeleteOutlined />}>
							Видалити
						</Button>
					</Space>
				);
			}
		}
	].filter(item => !item.hidden);

	const mergedColumns = columns.map(col => {
		if (!col.editable) {
			return col;
		}

		return {
			...col,
			onCell: record => ({
				record,
				inputType: col.dataIndex === 'email' ? 'email' : 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record)
			})
		};
	});

	const handleAdd = () => {
		const newData = {
			key: count,
			email: '',
			name: ''
		};
		setTableData([...tableData, newData]);
		edit(newData);
		setCount(count + 1);
	};

	const changeTitle = e => {
		setTitle(e.target.value);
	};

	return (
		<>
			{checkUser ? (
				<Title level={3}>{title}</Title>
			) : (
				title && (
					<Space>
						<Input
							defaultValue={title}
							placeholder="Назва списку"
							size="large"
							name="title"
							style={{ width: '100%', minWidth: 350 }}
							onChange={changeTitle}
							disabled={loading}
						/>

						<AsyncButton disabled={loading} size="large" type="primary" onClick={updateList}>
							Оновити назву
						</AsyncButton>
					</Space>
				)
			)}

			<Divider />
			<Title level={4}>Кількість контактів: {count}</Title>
			<Form form={form} component={false}>
				<Table
					components={{
						body: {
							cell: EditableCell
						}
					}}
					ref={ref}
					dataSource={tableData}
					columns={mergedColumns}
					rowClassName="editable-row"
					pagination={{ position: ['topLeft'], onChange: cancel }}
				/>
			</Form>
			<Button
				onClick={handleAdd}
				type="dashed"
				style={{
					marginTop: 16
				}}
				icon={<PlusOutlined />}
			>
				Додати контакт
			</Button>
			<Divider />
			<Space>
				<Button icon={<LeftOutlined />} size="large" onClick={goBack}>
					Назад
				</Button>
				<AsyncButton size="large" type="primary" disabled={loading} onClick={updateList} icon={<CheckCircleOutlined />}>
					Зберегти
				</AsyncButton>
			</Space>
		</>
	);
};
export default MailListEdit;

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
	const isEmail = inputType === 'email';
	const isNumber = inputType === 'number';

	const inputNode = isNumber ? <InputNumber /> : isEmail ? <Input type="email" /> : <Input />;
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					style={{
						margin: 0
					}}
					rules={[
						{
							required: true,
							message: `${title} не вказано`,
							whitespace: true,
							type: isNumber ? 'number' : isEmail ? 'email' : 'string'
						}
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};
