import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, MailOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/UserContext';
import { openNotification } from '../../utils/openNotification';
import axios from '../../utils/axios';
import AsyncButton from '../AsyncButton';

const Register = () => {
	const { register } = useAuth();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const onFinish = ({ email, password }) => {
		setLoading(true);
		register(email, password)
			.then(({ user }) => {
				axios
					.post('createUser.php', `id=${user.uid}&email=${user.email}`)
					.then(() => {
						navigate('/', { replace: true });
						openNotification('Ласкаво просимо!', `Ви успішно зареєструвались як ${user.email}!`, 'success');
					})
					.catch(() => {
						openNotification('Помилка', 'Не вдалося зареєструватися', 'error');
					});
			})
			.catch(({ code, message }) => {
				openNotification(code, message, 'error');
				setLoading(false);
			});
	};
	const { Item } = Form;

	return (
		<>
			<Form name="register" onFinish={onFinish} layout="vertical">
				<Item
					name="email"
					rules={[
						{
							required: true,
							message: 'Будь ласка, введіть коректний E-mail',
							type: 'email'
						}
					]}
				>
					<Input prefix={<MailOutlined />} type="email" placeholder="E-mail" size="large" />
				</Item>
				<Item
					name="password"
					rules={[
						{
							required: true,
							message: 'Будь ласка, введіть пароль'
						},
						{ min: 6, message: 'Пароль має містити не менше 6 символів' }
					]}
				>
					<Input.Password
						prefix={<LockOutlined />}
						placeholder="Пароль"
						size="large"
						iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
					/>
				</Item>
				<Item
					name="password-confirm"
					rules={[
						{ required: true, message: 'Будь ласка, підтвердіть пароль' },
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								}

								return Promise.reject(new Error('Паролі не співпадають'));
							}
						})
					]}
				>
					<Input.Password
						prefix={<LockOutlined />}
						size="large"
						placeholder="Підтвердження пароля"
						iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
					/>
				</Item>
				<Item>
					<AsyncButton type="primary" htmlType="submit" size="large" disabled={loading}>
						Зареєструватись
					</AsyncButton>
				</Item>
			</Form>
			<Link to="/login">Є акаунт, авторизуватись</Link>
		</>
	);
};
export default Register;
