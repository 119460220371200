import { FileTextOutlined, IdcardOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

function getItem(label, key, icon, children) {
	return {
		key,
		icon,
		children,
		label
	};
}

const items = () => {
	return [
		getItem(<Link to="/">Звіти</Link>, '1', <FileTextOutlined />),
		getItem(<Link to="/list">Розсилки</Link>, '2', <MailOutlined />),
		getItem(<Link to="/mlists">Списки отримувачів</Link>, '4', <IdcardOutlined />),
		getItem(<Link to="/users">Користувачі</Link>, '3', <UserOutlined />)
	];
};

export default items;
