import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Divider, Form, Input, Space, Spin } from 'antd';
import { useEffect, useState } from 'react';
import AsyncButton from '../../components/AsyncButton';
import { useMenuContext } from '../../context/MenuContext';
import { useAuth } from '../../context/UserContext';
import { openNotification } from '../../utils/openNotification';
import { renderRole } from '../../utils/renderRoles';

const Profile = () => {
	const [loading, setLoading] = useState(false);
	const { user, userData, updateUserPassword, logOut } = useAuth();
	const { setPageTitle, setSelectedKeys } = useMenuContext();

	useEffect(() => {
		const updateMenuAndTitle = () => {
			setPageTitle('Налаштування профілю');
			setSelectedKeys('-1');
		};
		updateMenuAndTitle();
		return updateMenuAndTitle;
	}, [setPageTitle, setSelectedKeys]);

	const { Item } = Form;

	const onFinish = ({ password }) => {
		setLoading(true);
		updateUserPassword(password)
			.then(() => {
				setLoading(false);
				logOut();
				openNotification('Пароль оновлено', 'Будь ласка, увійдіть в систему з новим паролем', 'success');
			})
			.catch(() => {
				setLoading(false);
				openNotification('Помилка', 'Не вдалося оновити пароль', 'error');
			});
	};

	return !userData ? (
		<Spin size="large" />
	) : (
		<>
			<div>
				Ваш E-mail / логін: <h2>{user.email}</h2>
			</div>
			<Divider />
			<div>
				<Space>
					<span>Ваша роль:</span>
					{renderRole(userData.role)}
				</Space>
			</div>
			<Divider />
			<h3>Оновити пароль</h3>
			<Form layout="vertical" wrapperCol={{ span: 12 }} onFinish={onFinish}>
				<Item
					label="Поточний пароль"
					name="password_current"
					rules={[{ required: true, message: 'Будь ласка, введіть свій поточний пароль' }]}
				>
					<Input.Password size="large" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
				</Item>
				<Item
					label="Новий пароль"
					name="password"
					dependencies={['password_current']}
					rules={[
						{ required: true, message: 'Будь ласка, введіть новий пароль' },
						{ min: 6, message: 'Пароль має містити мінімум 6 символів' },
						({ getFieldValue }) => ({
							validator() {
								if (getFieldValue('password_current') !== '') {
									return Promise.resolve();
								}
								return Promise.reject('Будь ласка, введіть також свій поточний пароль');
							}
						})
					]}
				>
					<Input.Password size="large" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
				</Item>
				<Item
					label="Підтвердження пароля"
					name="password_confirm"
					dependencies={['password']}
					rules={[
						{ required: true, message: 'Будь ласка, підтвердіть пароль' },
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								}

								return Promise.reject(new Error('Паролі не співпадають'));
							}
						})
					]}
				>
					<Input.Password size="large" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
				</Item>
				<Item>
					<AsyncButton type="primary" htmlType="submit" size="large" disabled={loading}>
						Оновити пароль
					</AsyncButton>
				</Item>
			</Form>
		</>
	);
};
export default Profile;
