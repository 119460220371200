import { DownloadOutlined, LoadingOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import { useState } from 'react';
import axios from '../../utils/axios';
import { openNotification } from '../../utils/openNotification';

const TestSend = ({ id, priceName }) => {
	const [loading, setLoading] = useState(false);
	const [emailValue, setEmailValue] = useState('');

	const emailHandler = e => {
		setEmailValue(e.target.value);
	};

	const onFinish = ({ email }) => {
		setLoading(true);
		axios
			.post('sendSingleMail.php', `email=${email}&id=${id}`)
			.then(() => {
				openNotification('Успіх', `Тестовий лист відправлено на ${email}`, 'success');
				setLoading(false);
			})
			.catch(() => {
				openNotification('Помилка', 'Під час відправки відбулась помилка', 'error');
				setLoading(false);
			});
	};

	return (
		<Row gutter={16}>
			<Col xs={{ span: 24 }} lg={{ span: 8 }}>
				<Button
					type="primary"
					icon={<DownloadOutlined />}
					href={priceName && `https://price.kaiser.com.ua/uploaded_files/${priceName}`}
					disabled={priceName === null}
				>
					Скачати актуальний прайс
				</Button>
			</Col>
			<Col xs={{ span: 24 }} lg={{ span: 8 }}>
				<Form name="test-send" onFinish={onFinish}>
					<Form.Item>
						<Input.Group compact>
							<Form.Item
								name="email"
								style={{ width: 'calc(100% - 200px)', maxWidth: 500 }}
								rules={[
									{
										message: 'Будь ласка, введіть коректний E-mail',
										type: 'email'
									}
								]}
							>
								<Input prefix={<MailOutlined />} type="email" placeholder="E-mail" onChange={emailHandler} />
							</Form.Item>
							<Form.Item>
								<Button
									type="primary"
									htmlType="submit"
									icon={loading ? <LoadingOutlined /> : null}
									disabled={emailValue.length === 0 || loading || !priceName}
								>
									{loading ? 'Зачекайте...' : 'Тестувати розсилку'}
								</Button>
							</Form.Item>
						</Input.Group>
					</Form.Item>
				</Form>
			</Col>
		</Row>
	);
};
export default TestSend;
