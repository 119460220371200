import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Empty, Table } from 'antd';
import axios from '../../utils/axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMenuContext } from '../../context/MenuContext';
import { useAuth } from '../../context/UserContext';
import { renderRole } from '../../utils/renderRoles';

const Users = () => {
	const [tableData, setTableData] = useState([]);
	const { setPageTitle, setSelectedKeys } = useMenuContext();
	const navigate = useNavigate();

	const { userData } = useAuth();
	const checkUser = userData && userData.role !== 'admin';

	useEffect(() => {
		const updateMenuAndTitle = () => {
			setPageTitle('Список користувачів');
			setSelectedKeys('3');
		};
		updateMenuAndTitle();
		return updateMenuAndTitle;
	}, [setPageTitle, setSelectedKeys]);

	useEffect(() => {
		const fetchData = () => {
			axios.get('getList.php?table=users').then(res => {
				const { data } = res;
				setTableData(
					data.map(item => ({
						key: item.id,
						id: item.id,
						email: item.email,
						role: item.role
					}))
				);
			});
		};
		fetchData();

		return fetchData;
	}, []);

	const columns = [
		{
			title: 'E-mail',
			dataIndex: 'email',
			key: 'email'
		},
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id'
		},
		{
			title: 'Роль',
			dataIndex: 'role',
			key: 'role',
			render: (text, record) => renderRole(record.role)
		},
		{
			title: 'Операції',
			key: 'action',
			render: (text, record) => (
				<Button
					type="link"
					onClick={() => {
						navigate(`/users/${record.id}`);
					}}
					icon={checkUser ? <EyeOutlined /> : <EditOutlined />}
				>
					{checkUser ? 'Переглянути' : 'Редагувати'}
				</Button>
			)
		}
	];

	return tableData.length > 0 ? (
		userData && <Table dataSource={tableData} columns={columns} pagination={{ position: ['topRight'] }} />
	) : (
		<Empty description="Немає користувачів" />
	);
};
export default Users;
