import { Table, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useMenuContext } from '../../context/MenuContext';
import axios from '../../utils/axios';

const Reports = () => {
	const [loading, setLoading] = useState(false);
	const [tableData, setTableData] = useState([]);
	const { setPageTitle, setSelectedKeys } = useMenuContext();

	useEffect(() => {
		const updateMenuAndTitle = () => {
			setPageTitle('Звіти');
			setSelectedKeys('1');
		};
		updateMenuAndTitle();
		return updateMenuAndTitle;
	}, [setPageTitle, setSelectedKeys]);

	useEffect(() => {
		const fetchData = () => {
			setLoading(true);
			axios.get('getList.php?table=logs').then(res => {
				const { data } = res;
				setTableData(
					data.map(item => ({
						key: item.id,
						log: item.log,
						logType: item.log_type,
						timeStamp: item.timestamp
					}))
				);
				setLoading(false);
			});
		};
		fetchData();

		return fetchData;
	}, []);

	const columns = [
		{
			title: 'Дата і час',
			dataIndex: 'timeStamp',
			key: 'timeStamp',
			defaultSortOrder: 'descend',
			sortDirections: ['descend'],
			sorter: (a, b) => moment(a.timeStamp).unix() - moment(b.timeStamp).unix()
		},
		{
			title: 'Статус',
			dataIndex: 'logType',
			render: (text, record) => <Tag color={record.logType}>{record.logType === 'green' ? 'Успіх' : 'Помилка'}</Tag>
		},
		{
			title: 'Подія',
			dataIndex: 'log',
			key: 'log'
		}
	];

	return (
		<Table
			dataSource={tableData}
			columns={columns}
			pagination={{ position: ['topRight'] }}
			loading={loading && { tip: 'Дані завантажуються' }}
			locale={{ emptyText: 'Немає звітів' }}
		/>
	);
};
export default Reports;
