import { createContext, useContext, useState } from 'react';

const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
	const [selectedKeys, setSelectedKeys] = useState('-1');
	const [pageTitle, setPageTitle] = useState('Звіти');

	const value = { selectedKeys, setSelectedKeys, pageTitle, setPageTitle };

	return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

export const useMenuContext = () => {
	return useContext(MenuContext);
};
