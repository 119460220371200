import { Card, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { Outlet, useLocation } from 'react-router-dom';
import ThemeSwitcher from '../ThemeSwitcher';
import styles from './Layout.module.less';

export const LoginLayout = () => {
	const location = useLocation();

	const title = () => {
		switch (location.pathname) {
			case '/login':
				return 'Вхід в систему';
			case '/login/register':
				return 'Реєстрація';
			case '/login/forgot-password':
				return 'Відновлення пароля';
			default:
				return 'Вхід в систему';
		}
	};

	return (
		<Layout className={styles.loginLayout}>
			<Helmet>
				<title>{title()} | Price Sender</title>
			</Helmet>
			<div className={styles.themeSwitcher}>
				<ThemeSwitcher />
			</div>
			<div className={styles.cardLayout}>
				<Card title={title()} className={styles.cardContainer}>
					<Outlet />
				</Card>
			</div>
		</Layout>
	);
};
