import { LoadingOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Input, Tooltip } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/UserContext';
import { openNotification } from '../../utils/openNotification';

const ProfileButton = () => {
	const { logOut } = useAuth();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const logoutHandler = () => {
		setLoading(true);
		logOut()
			.then(() => {
				navigate('/login', { replace: true });
				openNotification('Усього найкращого!', 'Ви успішно вийшли з системи', 'success');
			})
			.catch(({ code, message }) => {
				openNotification(code, message, 'error');
				setLoading(false);
			});
	};

	const viewProfile = () => {
		navigate('/profile');
	};

	return (
		<Input.Group compact>
			<Tooltip title="Переглянути і налашутвати профіль">
				<Button type="primary" onClick={viewProfile}>
					<UserOutlined /> Профіль
				</Button>
			</Tooltip>
			<Tooltip title="Вийти">
				<Button onClick={logoutHandler} type="outline" disabled={loading}>
					{loading ? <LoadingOutlined /> : <LogoutOutlined />}
				</Button>
			</Tooltip>
		</Input.Group>
	);
};
export default ProfileButton;
