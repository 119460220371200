import { Layout, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { useMenuContext } from '../../context/MenuContext';
import useStore from '../../store';
import Logo from '../Logo';
import items from './items';

const Sidebar = () => {
	const [collapsed, setCollapsed] = useState(window.innerWidth > 1240);
	const { selectedKeys } = useMenuContext();
	const { darkMode } = useStore();
	const { Sider } = Layout;

	const onCollapse = collapsed => {
		setCollapsed(!collapsed);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.addEventListener('resize', null);
		};
	}, []);

	const handleResize = () => {
		setCollapsed(window.innerWidth > 1240);
	};

	return (
		<Sider collapsible collapsed={!collapsed} onCollapse={onCollapse} className="site-layout-background">
			<Logo short={!collapsed} />
			<Menu theme={darkMode ? 'dark' : 'light'} mode="inline" items={items()} selectedKeys={[selectedKeys]} />
		</Sider>
	);
};
export default Sidebar;
